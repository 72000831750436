import { useContext, useState } from "react";
import { AuthContext } from "contexts/Auth";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import StatementStats from "components/stats/Statement";
import TransactionsList from "components/lists/Transactions";
import { LIST_TRANSACTIONS } from "utils/queries";
import toast from "react-hot-toast";

export default function Statement(props) {
  const { user } = useContext(AuthContext);

  const dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 6))
    .toISOString()
    .split("T")[0];

  const dateTo = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];

  const [summary, setSummary] = useState({
    // module: "PRODUCE",
    debit: 0,
    credit: 0,
    balance: 0,
  });
  const [transactions, setTransactions] = useState([]);
  const [filter /*, setFilter*/] = useState({
    module: "PRODUCE",
    description: "",
    date: [dateFrom, dateTo],
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);

  const { description, date } = filter;
  const [debouncedDescription] = useDebounce(description, 2000);
  const [debouncedDate] = useDebounce(date, 2000);

  const { loading, refetch } = useQuery(LIST_TRANSACTIONS, {
    onCompleted: (data) => {
      if (data?.transactions?.success) {
        setSummary({
          ...summary,
          debit: data?.transactions?.summary?.debits,
          credit: data?.transactions?.summary?.credits,
          balance: data?.transactions?.summary?.balance,
        });

        setTransactions(data?.transactions?.list);

        setTotal(data?.transactions?.total);
        setMaxPage(Math.ceil(data?.transactions?.total / limit));
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      module: "PRODUCE",
      reference: user?.data?.location,
      date: debouncedDate,
      description: !!debouncedDescription ? debouncedDescription : undefined,
      limit,
      page,
    },
    fetchPolicy: "network-only",
  });

  return (
    <UserLayout title="Statement">
      <HeadSection heading="Statement"></HeadSection>
      <main>
        <section className="flex flex-col space-y-6 py-6">
          <div>
            <h2 className="text-lg font-semibold mb-2 mx-2">Summary</h2>
            <StatementStats summary={summary} />
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2 mx-2">
              Transactions (last 6 months)
            </h2>
            <TransactionsList
              loading={loading}
              refetch={refetch}
              transactions={transactions}
              total={total}
              page={page}
              setPage={setPage}
              limit={limit}
              maxPage={maxPage}
            />
          </div>
        </section>
      </main>
    </UserLayout>
  );
}
