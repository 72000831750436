import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "contexts/Auth";
import Login from "pages/Login";
import Reset from "pages/Reset";
import Profile from "pages/Profile";
import Home from "pages/Home";
import Order from "pages/Order";
import Statement from "pages/Statement";
import Inventory from "pages/Inventory";
import Error from "pages/Error";

function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <PublicRoute>
            <Reset />
          </PublicRoute>
        }
      />
      {/* protected routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order/:id/summary"
        element={
          <ProtectedRoute>
            <Order mode="SUMMARY" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order/:id/fulfill"
        element={
          <ProtectedRoute>
            <Order mode="FULFILL" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order/:id/request"
        element={
          <ProtectedRoute>
            <Order mode="REQUEST" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order/:id/confirm"
        element={
          <ProtectedRoute>
            <Order mode="CONFIRM" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/statement"
        element={
          <ProtectedRoute>
            <Statement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inventory"
        element={
          <ProtectedRoute>
            <Inventory />
          </ProtectedRoute>
        }
      />
      {/* open routes */}
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;

function PublicRoute(props) {
  const { user } = useContext(AuthContext);
  // user not logged in
  return <>{!user ? props.children : <Navigate to="/" />}</>;
}

function ProtectedRoute(props) {
  const { user } = useContext(AuthContext);
  // admin or manager user
  return <>{user ? props.children : <Navigate to="/login" />}</>;
}
