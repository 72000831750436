import { useContext } from "react";
import { AuthContext } from "contexts/Auth";
import UserLayout from "layouts/User";
import OrdersList from "components/lists/Orders";
import HeadSection from "components/sections/Head";

export default function Home(props) {
  const { user } = useContext(AuthContext);

  return (
    <UserLayout title="Orders">
      <HeadSection heading="Orders"></HeadSection>
      <main>
        {user?.data?.fulfills?.length > 0 && (
          <section className="flex flex-col gap-2 py-4">
            <h2 className="text-lg font-semibold mb-2 mx-2">
              Fulfill Order Items
            </h2>
            <OrdersList
              type="FULFILL"
              status={["PROCESSING"]}
              module={user.data.fulfills}
              location={user.data.location}
            />
          </section>
        )}
        <section className="flex flex-col gap-2 py-4">
          <h2 className="text-lg font-semibold mb-2 mx-2">
            Request Order Items
          </h2>
          <OrdersList
            type="REQUEST"
            location={user.data.location}
            status={["DRAFT"]}
          />
        </section>
        <section className="flex flex-col gap-2 py-4">
          <h2 className="text-lg font-semibold mb-2 mx-2">
            Recent Order History
          </h2>
          <OrdersList
            type="RECENT"
            location={user.data.location}
            status={["WAITING", "PROCESSING", "COMPLETED"]}
          />
        </section>
      </main>
    </UserLayout>
  );
}
