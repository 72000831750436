import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

export default function QuantityInput(props) {
  const { id, label = "#", value, update, disabled, updating } = props;

  const [quantity, setQuantity] = useState(0);

  const handleChange = (e) => {
    setQuantity(e.target.value * 100);
    debounced(e.target.value * 100);
  };

  const debounced = useDebouncedCallback((v) => {
    const isChanged = value !== v;
    if (isChanged) update(id, v);
  }, 1000);

  useEffect(() => {
    setQuantity(value);
  }, [value]);

  return (
    <div>
      <p className="text-sm rounded border-base-300 bg-base-300 pl-2">
        {label}{" "}
        <span className="font-mono font-semibold">
          <input
            type="number"
            className="w-28 rounded border-base-300 active:border-primary focus:border-primary disabled:bg-base-300 disabled:cursor-not-allowed"
            inputMode="decimal"
            disabled={disabled || updating === id}
            min={"0"}
            step={0.25}
            placeholder="0"
            value={!!quantity ? quantity / 100 : ""}
            onChange={handleChange}
          />
        </span>
      </p>
    </div>
  );
}
