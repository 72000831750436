import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password, role: "LOCATION") {
      code
      message
      success
      token
    }
  }
`;

export const RESET = gql`
  mutation Reset($username: String!) {
    reset(username: $username, role: "LOCATION") {
      code
      message
      success
      user {
        id
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($inputs: profileInput!) {
    updated: updateProfile(inputs: $inputs) {
      code
      message
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation UpdatePassword($password: String!) {
    updated: updatePassword(password: $password) {
      code
      message
      success
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($inputs: orderInput!) {
    updated: updateOrder(inputs: $inputs) {
      code
      success
      message
      order {
        id
      }
    }
  }
`;

export const UPDATE_ORDER_ITEM = gql`
  mutation UpdateItem($inputs: ItemInput!) {
    updated: updateItem(inputs: $inputs) {
      code
      success
      message
      item {
        id
      }
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation CreateComment($inputs: CommentInput!) {
    created: createComment(inputs: $inputs) {
      code
      success
      message
      comment {
        id
      }
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($inputs: CommentInput!) {
    updated: updateComment(inputs: $inputs) {
      code
      success
      message
      comment {
        id
      }
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleted: deleteComment(id: $id) {
      code
      success
      message
      comment {
        id
      }
    }
  }
`;

export const CREATE_STOCK = gql`
  mutation CreateStock($inputs: StockInput!) {
    created: createStock(inputs: $inputs) {
      code
      success
      message
      stock {
        id
      }
    }
  }
`;

export const DELETE_STOCK = gql`
  mutation DeleteStock($id: ID!) {
    deleted: deleteStock(id: $id) {
      code
      success
      message
    }
  }
`;
