import { gql } from "@apollo/client";

export const PROFILE = gql`
  query Profile {
    profile {
      email
      username
    }
  }
`;

export const LIST_ORDERS = gql`
  query Orders(
    $module: [String]
    $number: String
    $date: [String]
    $status: [String]
    $location: ID
    $page: Int
    $limit: Int
  ) {
    orders(
      module: $module
      number: $number
      date: $date
      status: $status
      location: $location
      page: $page
      limit: $limit
    ) {
      code
      success
      message
      total
      page
      limit
      list: orders {
        id
        number
        module
        date
        close
        amounts {
          total
        }
        status
        created
        updated
      }
    }
  }
`;

export const GET_ORDER = gql`
  query Order($id: ID, $location: ID) {
    order(id: $id, location: $location) {
      code
      success
      message
      order {
        id
        number
        module
        date
        close
        timestamps {
          closed
          processed
          completed
        }
        amounts {
          cost
          expense
          total
        }
        counts {
          locations
          items
        }
        invoices {
          id
          location {
            id
            label
            type
          }
          total
          costs {
            expenses
            items
          }
          link
        }
        status
        created
        updated
        counts {
          locations
          items
          expenses
          comments
        }
      }
    }
  }
`;

export const LIST_ORDER_ITEMS = gql`
  query Items($name: String, $order: ID, $location: ID) {
    items(name: $name, order: $order, location: $location) {
      code
      success
      message
      total
      list: items {
        id
        product {
          id
          label
          type
        }
        order {
          id
          label
          type
        }
        name
        group
        unit
        thumbnail
        highlighted
        flags {
          collected
          delivered
          notified
          requested
        }
        quantities {
          requested
          notified
          collected
          delivered
        }
        amounts {
          average
          total
          rounding
        }
        counts {
          comments
        }
      }
      summary {
        collections
        deliveries
        notifications
        requests
      }
    }
  }
`;

export const LIST_REQUESTABLE_ITEMS = gql`
  query RequestableItems($order: ID!, $location: ID!, $name: String) {
    items: requestableItems(order: $order, location: $location, name: $name) {
      code
      success
      message
      list: requests {
        id
        quantity
        thing {
          id
          label
          thumbnail
          unit
          group
          type
          flags {
            requested
            notified
            highlighted
            delivered
            collected
          }
        }
      }
      total
    }
  }
`;

export const LIST_DELIVERABLE_ITEMS = gql`
  query FulfillableItems(
    $order: ID!
    $location: ID
    $supplier: ID
    $confirming: Boolean
    $name: String
  ) {
    items: deliverableItems(
      order: $order
      location: $location
      supplier: $supplier
      confirming: $confirming
      name: $name
    ) {
      code
      success
      message
      list: deliveries {
        id
        quantity
        thing {
          id
          label
          thumbnail
          unit
          group
          type
          flags {
            requested
            notified
            highlighted
            delivered
            collected
          }
        }
        requested
        notified
      }
      total
      options {
        locations {
          id
          label
          type
        }
        suppliers {
          id
          label
          type
        }
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query Comments($entity: String, $reference: ID) {
    comments(entity: $entity, reference: $reference) {
      code
      success
      message
      list: comments {
        id
        by
        on
        message
      }
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query Transactions(
    $module: String
    $reference: ID
    $date: [String]
    $description: String
    $category: String
    $order: ID
    $limit: Int
    $page: Int
  ) {
    transactions(
      module: $module
      reference: $reference
      date: $date
      description: $description
      category: $category
      order: $order
      limit: $limit
      page: $page
    ) {
      code
      success
      message
      total
      page
      limit
      list: transactions {
        id
        date
        module
        entity {
          id
          type
          label
        }
        type
        category
        description
        amount
        receipt
        created
        updated
      }
      summary {
        credits
        debits
        balance
      }
      options {
        modules
        entities {
          id
          type
          label
        }
      }
    }
  }
`;

export const LIST_STOCKS = gql`
  query Stocks(
    $module: String
    $location: ID
    $product: ID
    $date: [String]
    $limit: Int
    $page: Int
  ) {
    stocks(
      module: $module
      location: $location
      product: $product
      date: $date
      limit: $limit
      page: $page
    ) {
      code
      success
      message
      total
      page
      limit
      list: stocks {
        id
        date
        module
        type
        category
        notes
        quantity
        unit
        location {
          id
          name
        }
        product {
          id
          name
          thumbnail
        }
        created
        updated
        highlighted
      }
      summary {
        inwards
        outwards
        total
      }
      options {
        modules
        locations {
          id
          type
          label
        }
        products {
          id
          type
          label
          module
        }
      }
    }
  }
`;

export const GET_STOCK = gql`
  query Stock($id: ID!) {
    stock(id: $id) {
      code
      success
      message
      stock {
        id
        date
        module
        type
        category
        notes
        quantity
        unit
        location {
          id
          name
        }
        product {
          id
          name
          thumbnail
        }
        created
        updated
      }
    }
  }
`;

export const STOCK_CONSTANTS = gql`
  query StockConstants($module: String, $role: String, $feature: String) {
    modules: availableModules(feature: $feature, role: $role) {
      value
      label
    }
    categories: stockCategories(module: $module, role: $role) {
      value
      label
      type
    }
    locations {
      locations {
        id
        name
      }
    }
    products: stockProducts(module: $module) {
      value
      label
      unit
      group
      module
    }
  }
`;
