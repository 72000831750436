import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_DELIVERABLE_ITEMS } from "utils/queries";
import { UPDATE_ORDER_ITEM, UPDATE_ORDER } from "utils/mutations";
import QuantityInput from "components/inputs/Quantity";
import { unit } from "utils/helpers";
import clsx from "clsx";
import LoadingLayout from "layouts/Loading";

export default function FulfillItemsList(props) {
  const { order, disabled, refreshOrder } = props;

  const [items, setItems] = useState([]);
  const [updating, setUpdating] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [name, setName] = useState("");

  const [debouncedName] = useDebounce(name, 500);

  const { loading: fetching, refetch } = useQuery(LIST_DELIVERABLE_ITEMS, {
    variables: {
      order,
      location: !!selectedLocation ? selectedLocation : null,
      confirming: false,
      name: !!debouncedName ? debouncedName : null,
    },
    onCompleted: (data) => {
      if (data.items?.success) {
        setItems(data.items?.list);
        setLocations(data.items?.options?.locations);
      }
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [deliverItem] = useMutation(UPDATE_ORDER_ITEM, {
    onCompleted: (data) => {
      setUpdating(null);
      if (data?.updated?.success) {
        toast.success("Item updated successfully");
        refetch();
        refreshOrder();
      }
      if (!data?.updated?.success) toast.error("Item update failed");
    },
    onError: (error) => {
      setUpdating(null);
      toast.error(error.message);
      console.error(error);
    },
  });

  const [completeOrder, { loading: completing }] = useMutation(UPDATE_ORDER, {
    onCompleted: (data) => {
      if (data?.updated?.success) {
        toast.success("Order completed successfully");
        refetch();
      }
      if (!data?.updated?.success) toast.error("Order completion failed");
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const handleDeliver = (id, qty) => {
    // uses item id and quantity
    const inputs = {
      id,
      deliver: {
        location: selectedLocation,
        quantity: qty,
        kind: "internal",
      },
    };
    setUpdating(id);

    deliverItem({ variables: { inputs } });
  };

  const handleComplete = () => {
    if (!window.confirm("Are you sure you want to complete this order?"))
      return;
    completeOrder({
      variables: { inputs: { id: order, status: "COMPLETED" } },
    });
  };

  return (
    <>
      <section>
        <div className="flex flex-wrap flex-col md:flex-row md:justify-between md:items-baseline mb-4">
          {/* title */}
          <h2 className="text-lg font-semibold mb-2 mx-2">Fulfill Items</h2>
          {/* select supplier */}
          <div className="flex flex-row gap-2 items-center mx-2">
            <button
              className={clsx(
                "btn btn-sm btn-outline btn-primary",
                completing && "loading"
              )}
              onClick={handleComplete}
            >
              Complete Order
            </button>
            <label htmlFor="supplier" className="text-sm font-semibold sr-only">
              Location
            </label>
            <select
              name="supplier"
              id="supplier"
              className="rounded border-2 border-error w-full"
              disabled={disabled || fetching || !!updating}
              onChange={(e) => setSelectedLocation(e.target.value)}
            >
              <option value="">All locations</option>
              {locations.map((value, index) => (
                <option key={index} value={value.id}>
                  {value.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* filter by name */}
        <div className="my-2">
          <input
            type="search"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Search products"
            className="input w-full bg-white"
          />
        </div>
        {fetching ? (
          <LoadingLayout />
        ) : (
          <>
            {items && items.length > 0 ? (
              <>
                <ul className="flex flex-col gap-1">
                  {items.map((value, index) => (
                    <li className="flex bg-white rounded px-1 py-1" key={index}>
                      <div className="flex flex-col flex-1 px-1 py-2">
                        <div className="flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
                          {/* product info */}
                          <div className="flex flex-row gap-2 items-center">
                            <div className="font-bold font-mono text-xs">
                              #{index + 1}
                            </div>
                            <div className="relative z-10 flex-shrink-0 w-12">
                              <img
                                src={value.thing.thumbnail}
                                className="min-w-12 min-h-12 w-12 h-12 object-cover rounded"
                                alt={value.thing.label ?? "-"}
                              />
                              {value.thing?.flags?.highlighted && (
                                <span className="absolute -top-1 -left-1 flex h-2 w-2">
                                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success opacity-75"></span>
                                  <span className="relative inline-flex rounded-full h-2 w-2 bg-success"></span>
                                </span>
                              )}
                            </div>
                            <div className="flex-1 flex-shrink-0 px-2">
                              <h3 className="text-lg font-semibold tracking-wider">
                                {value.thing.label ?? "-"}{" "}
                              </h3>
                              <span className="text-xs font-semibold">
                                {value.thing.group ?? "-"}
                              </span>{" "}
                              -{" "}
                              <span className="text-xs">
                                {value.thing.unit ?? "-"}
                              </span>
                            </div>
                          </div>
                          {/* parameters */}
                          <div className="flex flex-row justify-center items-center md:justify-end gap-2">
                            <div>
                              <p className="text-sm">
                                Request:{" "}
                                <span className="font-mono font-bold text-md">
                                  {unit(value.requested)}
                                </span>
                              </p>
                            </div>
                            <QuantityInput
                              id={value.thing.id}
                              label="Deliver"
                              value={value.quantity}
                              update={handleDeliver}
                              disabled={!selectedLocation}
                              updating={updating}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <p className="text-center pt-2 text-xs tracking-wider font-bold">
                  Total: {items.length} items
                </p>
              </>
            ) : (
              <p className="text-center text-gray-500">No items found</p>
            )}
          </>
        )}
      </section>
    </>
  );
}
