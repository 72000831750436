import { useContext, useState } from "react";
import { AuthContext } from "contexts/Auth";
import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import InventoryStats from "components/stats/Inventory";
import StocksList from "components/lists/Stocks";
import { LIST_STOCKS } from "utils/queries";
import toast from "react-hot-toast";

export default function Inventory(props) {
  const { user } = useContext(AuthContext);

  const dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 3))
    .toISOString()
    .split("T")[0];

  const dateTo = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];

  const [summary, setSummary] = useState({
    module: "",
    inwards: 0,
    outwards: 0,
    total: 0,
  });
  const [stocks, setStocks] = useState([]);
  const [options, setOptions] = useState({
    modules: [],
    products: [],
  });
  const [filter, setFilter] = useState({
    module: undefined,
    product: undefined,
    date: [dateFrom, dateTo],
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);

  const { date, product } = filter;
  const [debouncedDate] = useDebounce(date, 2000);

  // find product by label from options
  const filterProduct = (product) => {
    const found = options.products.find((p) => p.label === product);
    return found?.id ?? undefined;
  };

  const { loading, refetch } = useQuery(LIST_STOCKS, {
    onCompleted: (data) => {
      if (data?.stocks?.success) {
        setSummary({
          ...summary,
          inwards: data?.stocks?.summary?.inwards,
          outwards: data?.stocks?.summary?.outwards,
          total: data?.stocks?.summary?.total,
        });

        setOptions({
          modules: data?.stocks?.options?.modules,
          products: data?.stocks?.options?.products,
        });

        setStocks(data?.stocks?.list);

        setTotal(data?.stocks?.total);
        setMaxPage(Math.ceil(data?.stocks?.total / limit));
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      module: !!filter.module ? filter.module : undefined,
      location: user?.data?.location,
      product: filterProduct(product),
      date: debouncedDate,
      limit,
      page,
    },
    fetchPolicy: "network-only",
  });

  return (
    <UserLayout title="Statement">
      <HeadSection heading="Statement"></HeadSection>
      <main>
        <section className="flex flex-col space-y-6 py-6">
          <div>
            <h2 className="text-lg font-semibold mb-2 mx-2">Summary</h2>
            <InventoryStats summary={summary} />
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2 mx-2">
              Stock Entries (last 6 months)
            </h2>
            <StocksList
              loading={loading}
              refetch={refetch}
              options={options}
              filter={filter}
              setFilter={setFilter}
              stocks={stocks}
              total={total}
              page={page}
              limit={limit}
              setPage={setPage}
              maxPage={maxPage}
              location={user?.data?.location}
              requests={user?.data?.requests}
              fulfills={user?.data?.fulfills}
            />
          </div>
        </section>
      </main>
    </UserLayout>
  );
}
