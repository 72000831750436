import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_STOCK, DELETE_STOCK } from "utils/mutations";
import LoadingLayout from "layouts/Loading";
import { unit, formatISODate, upperFirst } from "utils/helpers";
import clsx from "clsx";
import { TrashIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

export default function StocksList(props) {
  const {
    loading,
    refetch,
    options,
    filter,
    setFilter,
    stocks,
    total,
    page,
    limit,
    setPage,
    maxPage,
    location,
    requests,
    fulfills,
  } = props;

  const [product, setProduct] = useState(null);
  const [module, setModule] = useState(null);
  const [canAdd, setCanAdd] = useState(false);
  const [canRemove, setCanRemove] = useState(false);
  const [type, setType] = useState("OUT");
  const [category, setCategory] = useState("USAGE");
  const [quantity, setQuantity] = useState(0);

  const reset = () => {
    setProduct(null);
    setModule(null);
    setCanAdd(false);
    setCanRemove(false);
    setType("OUT");
    setCategory("USAGE");
    setQuantity(0);
    setFilter({
      ...filter,
      product: undefined,
    });
  };

  const [createStock, { loading: creating }] = useMutation(CREATE_STOCK, {
    onCompleted: (data) => {
      if (!data?.created?.success)
        toast.error("Stock creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("Stock created successfully");
        refetch();
        reset();
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [deleteStock] = useMutation(DELETE_STOCK, {
    onCompleted: (data) => {
      toast.success("Stock deleted successfully.");
      refetch();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const canDelete = (category) => {
    switch (category) {
      case "PURCHASE":
        return false;
      case "SALE":
        return false;
      default:
        return true;
    }
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  const handleProduct = (e) => {
    const found = options.products.find((p) => p.label === e.value);

    if (!!found) {
      setProduct(found.id);
      setModule(found.module);
    } else {
      setProduct(null);
      setModule(null);
    }
  };

  const handleCreate = () => {
    const inputs = {
      module,
      type,
      category,
      quantity,
      product,
      location,
    };

    createStock({ variables: { inputs } });
  };

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this stock entry?"))
      return;
    deleteStock({ variables: { id } });
  };

  useEffect(() => {
    if (type === "OUT") setCategory("USAGE");
    if (type === "IN") setCategory("ADDED");
  }, [type]);

  useEffect(() => {
    if (requests.includes(module)) {
      setCanRemove(true);
      setType("OUT");
    } else setCanRemove(false);
    if (fulfills.includes(module)) {
      setCanAdd(true);
      setType("IN");
    } else setCanAdd(false);
  }, [product, module, requests, fulfills]);

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col md:flex-row gap-2 w-full">
          {/* product */}
          <div className="w-full md:basis-1/2 lg:basis-2/3">
            <input
              type="search"
              list="products"
              className="input bg-white rounded w-full"
              placeholder="Filter by Product"
              htmlFor="products"
              value={filter.product ?? ""}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  product: e.target.value,
                });
                handleProduct(e.target);
              }}
            />
            <datalist id="products">
              {options?.products?.map((value, index) => (
                <option key={index} value={value.label}>
                  {value.label}
                </option>
              ))}
            </datalist>
          </div>
          {/* actions */}
          <div className="w-full md:basis-1/2 lg:basis-1/3 flex flex-nowrap">
            <select
              className="input min-w-[120px] bg-white rounded-none rounded-tl rounded-bl font-bold"
              disabled={!product || !module || (!canAdd && !canRemove)}
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="OUT" disabled={!canRemove}>
                REMOVE
              </option>
              <option value="IN" disabled={!canAdd}>
                ADD
              </option>
            </select>
            <input
              type="number"
              className="input min-w-20 w-full bg-white rounded-none"
              min={"0"}
              step={0.25}
              placeholder="0"
              disabled={!product || !module || (!canAdd && !canRemove)}
              value={!!quantity ? quantity / 100 : ""}
              onChange={(e) => setQuantity(e.target.value * 100)}
            />
            <button
              className={clsx(
                "btn btn-ghost bg-white rounded-none rounded-tr rounded-br text-primary disabled:bg-base-200",
                creating && "loading"
              )}
              disabled={!product || !module || !quantity}
              onClick={handleCreate}
            >
              Create Entry
            </button>
          </div>
        </div>
        {loading ? (
          <LoadingLayout />
        ) : (
          <ul className="flex flex-col gap-1">
            {stocks && stocks.length > 0 ? (
              stocks.map((value, index) => (
                <li
                  className="flex flex-row gap-2 bg-white items-center rounded py-2 md:py-1 px-4"
                  key={value.id}
                >
                  {/* date & desc */}
                  <div className="basis-2/3 flex flex-col md:flex-row gap-1 md:items-center">
                    <div className="basis-1/3 md:basis-1/5 flex flex-row items-center gap-3">
                      <p className="font-semibold text-sm">
                        #{page * limit + index + 1}
                      </p>
                      <small className="font-semibold md:max-w-[90px]">
                        {formatISODate(value.date, "DD-MM-YYYY hh:mm A")}
                      </small>
                    </div>
                    <div className="md:basis-1/2">
                      <div className="flex flex-row items-center rounded min-w-max">
                        <div className="flex-shrink-0 w-16">
                          <img
                            src={value.product?.thumbnail}
                            className="min-w-16 min-h-16 w-16 h-16 object-cover"
                            alt={value.product?.name}
                          />
                        </div>
                        <div className="flex-1 flex-shrink-0 p-4">
                          <h3 className="text-lg font-semibold tracking-wider">
                            {value.product?.name}
                          </h3>
                          <p>
                            <span className="font-normal">
                              {upperFirst(value.category.toLowerCase())}
                            </span>{" "}
                            {value.notes && <span>/ {value.notes}</span>}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* amounts */}
                  <div className="basis-1/3 flex flex-row justify-items-end items-center">
                    <div className="basis-full flex flex-row gap-2 justify-end items-center md:text-right">
                      <p className="md:text-right">
                        <span className={clsx("font-mono font-bold text-md")}>
                          {value.type === "IN" ? `+` : `-`}
                          {value.quantity
                            ? unit(value.quantity, value.unit)
                            : `-`}
                        </span>
                      </p>
                      <button
                        className="btn btn-ghost btn-sm btn-circle text-red-500"
                        disabled={!canDelete(value.category)}
                        onClick={() => handleDelete(value.id)}
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li className="bg-white rounded p-4 w-full text-center">
                No stocks found
              </li>
            )}
          </ul>
        )}
        {/* pagination */}
        <div className="flex flex-col md:flex-row gap-4 md:justify-between items-center">
          <div>
            <p>
              {total > 0 && (
                <>
                  Showing <span className="font-bold">{page * limit + 1}</span>{" "}
                  -{" "}
                  <span className="font-bold">
                    {page * limit + stocks?.length}{" "}
                  </span>
                  of{" "}
                </>
              )}
              <span className="font-bold">{total}</span> results
            </p>
          </div>
          <div className="btn-group">
            <button
              className="btn btn-outline btn-sm"
              disabled={page === 0 || maxPage <= 1}
              onClick={() => prevPage()}
            >
              Prev
            </button>
            <button
              className="btn btn-outline btn-sm"
              disabled={page === maxPage - 1 || maxPage <= 1}
              onClick={() => nextPage()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
