import { currency } from "utils/helpers";
import clsx from "clsx";

export default function StatementStats(props) {
  const { debit, credit, balance } = props.summary;

  return (
    <div className="stats stats-vertical md:stats-horizontal rounded w-full bg-white">
      <div className="stat">
        <div className="stat-title">For</div>
        <div className="stat-value font-bold text-2xl">Produce</div>
        <div className="stat-desc">Items</div>
      </div>

      <div className="stat">
        <div className="stat-title">Debit</div>
        <div className="stat-value font-bold text-2xl text-red-500">
          {currency(debit)}
        </div>
        <div className="stat-desc">Total charges</div>
      </div>

      <div className="stat">
        <div className="stat-title">Credit</div>
        <div className="stat-value font-bold text-2xl text-green-600">
          {currency(credit)}
        </div>
        <div className="stat-desc">Total payments</div>
      </div>

      <div className="stat">
        <div className="stat-title">Balance</div>
        <div
          className={clsx(
            "stat-value font-bold text-2xl",
            balance > 0 && "text-green-600",
            balance < 0 && "text-red-500"
          )}
        >
          {currency(balance)}
        </div>
        <div className="stat-desc">Net total</div>
      </div>
    </div>
  );
}
