import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { LIST_ORDER_ITEMS } from "utils/queries";
import Drawer from "layouts/Drawer";
import CommentsList from "components/lists/Comments";
import { unit, currency, formatISODate } from "utils/helpers";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { produceInvoice } from "utils/pdf";

export default function OrderItemsList(props) {
  const { order, summary, location, name } = props;

  const [items, setItems] = useState([]);
  const [openComments, setOpenComments] = useState(false);
  const [commentItem, setCommentItem] = useState({
    id: null,
    name: "",
  });

  const { startPolling, stopPolling, refetch } = useQuery(LIST_ORDER_ITEMS, {
    variables: { order, location },
    onCompleted: (data) => {
      if (data.items?.success) {
        setItems(data.items.list);
      }
    },
    onError: (error) => {
      toast.error("Failed to fetch order items");
      console.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const handlePrint = (type) => {
    const payload = {
      number: summary.number,
      date: formatISODate(summary.date, "DD/MM/YYYY"),
      name,
      items,
      expense: summary.amounts.expense,
    };

    if (type === "invoice") produceInvoice(payload);
  };

  useEffect(() => {
    startPolling(100000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  return (
    <>
      <section>
        <div className="flex flex-wrap flex-col md:flex-row md:justify-between md:items-baseline mb-4">
          <h2 className="text-lg font-semibold mb-2 mx-2">Items</h2>
          <div className="flex flex-row gap-2 items-center mx-2">
            {summary.module === "GROCERY" && summary.status === "COMPLETED" && (
              <a
                href={`/order/${order}/confirm`}
                className="btn btn-sm btn-outline btn-secondary"
              >
                Confirm Supplier Items
              </a>
            )}
            {summary.module === "PRODUCE" && summary.status === "COMPLETED" && (
              <button
                className="btn btn-sm btn-outline"
                onClick={() => handlePrint("invoice")}
              >
                Print Invoice
              </button>
            )}
          </div>
        </div>
        {items && items.length > 0 ? (
          <ul className="flex flex-col gap-1">
            {items.map((value, index) => (
              <li className="flex bg-white rounded px-2 py-1" key={index}>
                <div className="flex flex-col flex-1 px-2 py-2">
                  <div className="flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
                    {/* product info */}
                    <div className="flex flex-row gap-2 items-center">
                      <div className="font-bold font-mono text-xs">
                        #{index + 1}
                      </div>
                      <div className="relative z-10 flex-shrink-0 w-12">
                        <img
                          src={value.thumbnail}
                          className="min-w-12 min-h-12 w-12 h-12 object-cover rounded"
                          alt={value.name}
                        />
                        {value.highlighted && (
                          <span className="absolute -top-1 -left-1 flex h-2 w-2">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-2 w-2 bg-success"></span>
                          </span>
                        )}
                      </div>
                      <div className="flex-1 flex-shrink-0 px-2">
                        <h3 className="text-lg font-semibold tracking-wider">
                          {value.name}{" "}
                        </h3>
                        <span className="text-xs font-semibold">
                          {value.group}
                        </span>{" "}
                        - <span className="text-xs">{value.unit}</span>
                        {value.amounts?.average > 0 && (
                          <span className="text-xs font-semibold">
                            {" "}
                            @ {currency(value.amounts?.average ?? 0)}
                          </span>
                        )}
                      </div>
                      <div>
                        <button
                          className="bg-gray-100 p-1.5 rounded flex items-center gap-1"
                          onClick={() => {
                            setCommentItem({
                              id: value.id,
                              name: value.name,
                            });
                            setOpenComments(true);
                          }}
                        >
                          <span>
                            <ChatBubbleLeftEllipsisIcon className="w-4 h-4" />
                          </span>
                          <span className="font-mono text-xs">
                            {value.counts?.comments ?? 0}
                          </span>
                        </button>
                      </div>
                    </div>
                    {/* parameters */}
                    <div className="flex flex-row justify-center items-center md:justify-end gap-2">
                      <p className="text-sm">
                        Req.:{" "}
                        <span className="font-mono font-semibold">
                          {unit(value.quantities.requested)}
                        </span>
                      </p>{" "}
                      |
                      <p className="text-sm">
                        Del.:{" "}
                        <span className="font-mono font-semibold">
                          {unit(value.quantities.delivered)}
                        </span>
                      </p>
                      {value.amounts?.total > 0 && (
                        <p className="text-sm">
                          {" "}
                          | Total:{" "}
                          <span className="font-mono font-semibold">
                            {currency(value.amounts?.total ?? 0)}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-gray-500">No items found</p>
        )}
      </section>
      {openComments && (
        <Drawer setOpen={setOpenComments} title={commentItem.name}>
          <CommentsList
            entity={"Item"}
            reference={commentItem.id}
            alsoRefetch={refetch}
          />
        </Drawer>
      )}
    </>
  );
}
