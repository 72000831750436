import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "contexts/Auth";
import logo from "assets/logo.svg";
import {
  Bars3CenterLeftIcon,
  // ClipboardDocumentListIcon,
  EllipsisHorizontalIcon,
  QueueListIcon,
  InboxStackIcon,
} from "@heroicons/react/24/outline";

const year = new Date().getFullYear();

export default function UserLayout(props) {
  const { user, logout } = useContext(AuthContext);
  const [access] = useState(user?.data?.access);
  const [fulfills] = useState(user?.data?.fulfills);

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <header className="navbar bg-white fixed top-0 z-30 shadow">
        <div className="navbar-start gap-4 lg:gap-0">
          <div className="dropdown">
            <label
              tabIndex={0}
              className="btn btn-ghost text-secondary lg:hidden"
            >
              <Bars3CenterLeftIcon className="w-6 h-6 stroke-current" />
            </label>
            <ul
              tabIndex={0}
              className="menu menu-normal dropdown-content mt-6 p-2 shadow bg-white text-secondary rounded-box w-52"
            >
              <li className="gap-2">
                {menu(access, fulfills).map((item, index) => {
                  if (item.label)
                    return (
                      <NavLink key={index} to={item.path}>
                        {item.icon}
                        {item.label}
                      </NavLink>
                    );
                  return null;
                })}
              </li>
            </ul>
          </div>
          <Link to="/">
            <img src={logo} alt="App logo" className="w-36" />
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal text-secondary px-1">
            <li className="gap-2">
              {menu(user?.data?.permissions).map((item, index) => {
                if (item.label)
                  return (
                    <NavLink key={index} to={item.path}>
                      {item.icon}
                      {item.label}
                    </NavLink>
                  );
                return null;
              })}
            </li>
          </ul>
        </div>
        <div className="navbar-end">
          <div className="flex gap-2">
            <div className="dropdown dropdown-end">
              <label tabIndex="0" className="btn btn-secondary btn-outline">
                <EllipsisHorizontalIcon className="inline-block w-5 h-5 stroke-current" />
              </label>
              <ul
                tabIndex="0"
                className="dropdown-content menu p-2 shadow bg-white rounded-box w-52 mt-6"
              >
                <li>
                  <Link
                    className="uppercase text-secondary font-semibold"
                    to="/profile"
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  <div
                    className="uppercase text-secondary font-semibold"
                    onClick={() => logout()}
                  >
                    Logout
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <main className="w-full max-w-7xl mx-auto py-8 px-4 mt-20">
        {props.children}
      </main>
      <footer className="w-full max-w-7xl mx-auto pt-8 px-4">
        <div className="footer footer-center p-4 text-base-content border-t-2">
          <div>
            <p>
              Copyright © {year} - All right reserved by Radhe Group - Powered
              by{" "}
              <a href="https://logicsync.au" target="_blank" rel="noreferrer">
                LogicSync
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

const menu = (permissions) => [
  // {
  //   label: "Orders",
  //   path: "/orders",
  //   icon: <ClipboardDocumentListIcon className="h-6 w-6" />,
  // },
  {
    ...(permissions?.includes("STATEMENT") && {
      label: "Statement",
      path: "/statement",
      icon: <QueueListIcon className="h-6 w-6" />,
    }),
  },
  {
    ...(permissions?.includes("INVENTORY") && {
      label: "Inventory",
      path: "/inventory",
      icon: <InboxStackIcon className="h-6 w-6" />,
    }),
  },
];
