import { currency, formatISODate, upperFirst } from "utils/helpers";
import LoadingLayout from "layouts/Loading";
import clsx from "clsx";

export default function TransactionsList(props) {
  const {
    loading,
    refetch,
    transactions,
    total,
    page,
    limit,
    setPage,
    maxPage,
  } = props;

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  if (loading) return <LoadingLayout />;

  return (
    <div className="flex flex-col space-y-4">
      <ul className="flex flex-col gap-1">
        {transactions && transactions.length > 0 ? (
          transactions.map((value, index) => (
            <li
              className="flex flex-row gap-4 bg-white items-center rounded p-4"
              key={value.id}
            >
              {/* date & desc */}
              <div className="basis-2/3 flex flex-col md:flex-row gap-2 md:items-center">
                <div className="basis-1/3 md:basis-1/4 flex flex-row items-center gap-3">
                  <p className="font-semibold text-sm">
                    #{page * limit + index + 1}
                  </p>
                  <small className="font-semibold">
                    {formatISODate(value.date, "DD-MM-YYYY hh:mm A")}
                  </small>
                </div>
                <div className="md:basis-1/2">
                  <p>
                    <span className="font-bold">
                      {upperFirst(value.type.toLowerCase())}
                    </span>
                    <br />
                    {value.description}
                  </p>
                </div>
              </div>
              {/* amounts */}
              <div className="basis-1/3 flex flex-row justify-items-end items-center">
                <div className="basis-full flex flex-row gap-2 justify-end md:text-right">
                  <p className="md:text-right">
                    <span className={clsx("font-mono font-bold text-md")}>
                      {value.type === "CREDIT" ? `+` : `-`}
                      {value.amount ? currency(value.amount) : `-`}
                    </span>
                  </p>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li className="bg-white rounded p-4 w-full text-center">
            No transactions found
          </li>
        )}
      </ul>
      {/* pagination */}
      <div className="flex flex-col md:flex-row gap-4 md:justify-between items-center">
        <div>
          <p>
            {total > 0 && (
              <>
                Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
                <span className="font-bold">
                  {page * limit + transactions?.length}{" "}
                </span>
                of{" "}
              </>
            )}
            <span className="font-bold">{total}</span> results
          </p>
        </div>
        <div className="btn-group">
          <button
            className="btn btn-outline btn-sm"
            disabled={page === 0 || maxPage <= 1}
            onClick={() => prevPage()}
          >
            Prev
          </button>
          <button
            className="btn btn-outline btn-sm"
            disabled={page === maxPage - 1 || maxPage <= 1}
            onClick={() => nextPage()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
