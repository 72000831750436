import { useState, useContext, useEffect } from "react";
import { AuthContext } from "contexts/Auth";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ORDER } from "utils/queries";
import toast from "react-hot-toast";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import OrderStats from "components/stats/Order";
import OrderItemsList from "components/lists/OrderItems";
import RequestItemsList from "components/lists/RequestItems";
import FulfillItemsList from "components/lists/FulfillItems";
import ConfirmItemsList from "components/lists/ConfirmItems";
import CommentsList from "components/lists/Comments";

export default function Order(props) {
  const { mode } = props;

  const { user } = useContext(AuthContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const [summary, setSummary] = useState({
    number: "",
    module: "",
    status: "",
    date: "",
    total: 0,
    amounts: {
      cost: 0,
      expense: 0,
    },
    counts: {
      locations: 0,
    },
  });

  const { data, startPolling, stopPolling, refetch } = useQuery(GET_ORDER, {
    variables: {
      id,
      ...(mode !== "FULFILL" && {
        location: user.data.location,
      }),
    },
    onCompleted: (data) => {
      if (data.order?.success) {
        const { order } = data.order;

        const link = order.invoices[0]?.link ?? ""; // set link to first invoice

        setSummary({
          number: order.number,
          module: order.module,
          status: order.status,
          date: order.date,
          total: order.amounts?.total,
          link,
          amounts: {
            cost: order.amounts?.cost,
            expense: order.amounts?.expense,
          },
          counts: {
            locations: order.counts?.locations,
          },
        });

        if (mode === "REQUEST" && order.status !== "DRAFT")
          navigate(`/order/${id}/summary`);
        if (mode === "CONFIRM" && order.status !== "COMPLETED")
          navigate(`/order/${id}/summary`);
        if (mode === "FULFILL" && order.status !== "PROCESSING") navigate(`/`);
      } else {
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error("Failed to fetch order details");
      console.error(error);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.order?.success) {
      if (data.order.order.status !== "COMPLETED") startPolling(10000);
      else stopPolling();
    }
    return () => stopPolling();
  }, [data, startPolling, stopPolling]);

  return (
    <UserLayout title={`Order ${summary?.number}`}>
      <HeadSection heading={`${summary?.number}`}>
        <button
          className="btn btn-secondary btn-outline"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </HeadSection>
      <div className="flex flex-col gap-6 pt-6">
        {/* order summary */}
        <section>
          <h2 className="text-lg font-semibold mb-2 mx-2">Overview</h2>
          <OrderStats id={id} summary={summary} />
        </section>
        {/* order items list (SUMMARY mode) */}
        {mode === "SUMMARY" && (
          <>
            <OrderItemsList
              mode={mode}
              order={id}
              summary={summary}
              location={user.data.location}
              name={user.data.name}
            />
          </>
        )}
        {/* for REQUEST mode */}
        {mode === "REQUEST" && (
          <>
            <RequestItemsList
              mode={mode}
              order={id}
              summary={summary}
              location={user.data.location}
              refreshOrder={refetch}
            />
          </>
        )}
        {/* for FULFILL mode */}
        {mode === "FULFILL" && (
          <>
            <FulfillItemsList
              mode={mode}
              order={id}
              summary={summary}
              location={user.data.location}
              refreshOrder={refetch}
            />
          </>
        )}
        {/* for CONFIRM mode */}
        {mode === "CONFIRM" && (
          <>
            <ConfirmItemsList
              mode={mode}
              order={id}
              summary={summary}
              location={user.data.location}
              refreshOrder={refetch}
            />
          </>
        )}
        {/* order comments */}
        <section>
          <h2 className="text-lg font-semibold mb-2 mx-2">Comments</h2>
          <CommentsList entity={"Order"} reference={id} />
        </section>
      </div>
    </UserLayout>
  );
}
