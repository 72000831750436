import { unit } from "utils/helpers";
import clsx from "clsx";

export default function InventoryStats(props) {
  const { inwards, outwards, total } = props.summary;

  return (
    <div className="stats stats-vertical md:stats-horizontal rounded w-full bg-white">
      <div className="stat">
        <div className="stat-title">Inwards</div>
        <div className="stat-value font-bold text-2xl text-green-600">
          {unit(inwards)}
        </div>
        <div className="stat-desc">Total bought</div>
      </div>

      <div className="stat">
        <div className="stat-title">Outwards</div>
        <div className="stat-value font-bold text-2xl text-red-500">
          {unit(outwards)}
        </div>
        <div className="stat-desc">Total used / sold</div>
      </div>

      <div className="stat">
        <div className="stat-title">Total</div>
        <div
          className={clsx(
            "stat-value font-bold text-2xl",
            total > 0 && "text-green-600",
            total < 0 && "text-red-500"
          )}
        >
          {unit(total)}
        </div>
        <div className="stat-desc">Net total</div>
      </div>
    </div>
  );
}
