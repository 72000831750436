import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LIST_ORDERS } from "utils/queries";
import clsx from "clsx";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { statusTagColor, formatISODate } from "utils/helpers";
import toast from "react-hot-toast";

export default function OrdersList(props) {
  const { type, status, module, location } = props;

  const [orders, setOrders] = useState([]);

  const { startPolling, stopPolling } = useQuery(LIST_ORDERS, {
    variables: {
      module: module || null,
      location: type === "FULFILL" ? null : location,
      status,
      limit: 14,
    },
    onCompleted: (data) => {
      if (data.orders?.success) {
        setOrders(data.orders.list);
      }
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const getLink = (id) => {
    switch (type) {
      case "FULFILL":
        return `/order/${id}/fulfill`;
      case "REQUEST":
        return `/order/${id}/request`;
      case "CONFIRM":
        return `/order/${id}/confirm`;
      default:
        return `/order/${id}/summary`;
    }
  };

  useEffect(() => {
    startPolling(10000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  return (
    <section className="flex flex-col gap-6 py-1">
      <div>
        <ul className="flex flex-col gap-1">
          {orders && orders.length > 0 ? (
            orders.map((value, index) => (
              <li className="flex bg-white rounded" key={index}>
                <Link
                  to={getLink(value.id)}
                  className="bg-white w-full rounded"
                >
                  <div className="flex flex-row justify-between items-center gap-2 p-4">
                    <div className="flex flex-col gap-1">
                      <p className="text-md font-semibold">{value.number}</p>
                      <p className="text-sm">
                        {formatISODate(value.date, "dd DD/MM/YYYY")}{" "}
                        {!module && (
                          <span className="tracking-wide font-medium">
                            {" "}
                            | {value.module} ITEMS
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      {type === "FULFILL" && (
                        <div className="p-1.5 bg-info rounded-full animate-pulse" />
                      )}
                      {type === "REQUEST" && (
                        <div className="p-1.5 bg-accent rounded-full animate-pulse" />
                      )}
                      <p
                        className={clsx(
                          "text-xs py-1 px-2 rounded",
                          `${statusTagColor(value.status)}`
                        )}
                      >
                        {value.status}
                      </p>
                      <div className="bg-gray-100 p-1.5 rounded-full">
                        <ChevronRightIcon className="h-4 w-4 text-gray-700" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))
          ) : (
            <li className="flex bg-white rounded">
              <div className="bg-white w-full rounded">
                <p className="text-center p-4">No orders found</p>
              </div>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
}
