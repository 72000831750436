import { Link } from "react-router-dom";
import PublicLayout from "layouts/Public";
import ResetForm from "components/forms/Reset";

export default function Reset(props) {
  return (
    <PublicLayout title="Reset Password | Radhe App (Location)">
      <div className="text-center pb-4">
        <h1 className="text-4xl font-bold">Reset</h1>
        <div className="text-center pt-4">
          <p className="text-gray-600">
            Please provide your existing account username to receive a new
            password via email
          </p>
        </div>
      </div>
      <ResetForm />
      <div className="text-center pt-6">
        <Link to="/login" className="text-secondary">
          Back to login
        </Link>
      </div>
    </PublicLayout>
  );
}
