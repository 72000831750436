import clsx from "clsx";
import { statusTagColor, formatISODate, currency } from "utils/helpers";

export default function OrderStats(props) {
  const { module, date, status, total, link } = props.summary;

  return (
    <div className="stats stats-vertical md:stats-horizontal rounded w-full bg-white">
      {module && (
        <div className="stat">
          <div className="stat-title">Module</div>
          <div className="stat-value font-bold text-lg">{module}</div>
        </div>
      )}
      {date && (
        <div className="stat">
          <div className="stat-title">Date</div>
          <div className="stat-value font-bold text-lg">
            {formatISODate(date, "dd, DD MMM YYYY")}
          </div>
        </div>
      )}
      {status && (
        <div className="stat">
          <div className="stat-title">Status</div>
          <div className="stat-value text-sm">
            <span className={clsx("px-2 py-1 rounded", statusTagColor(status))}>
              {status}
            </span>
          </div>
        </div>
      )}
      {total > 0 && (
        <div className="stat">
          <div className="stat-title">Total</div>
          <div className="stat-value font-bold text-lg">{currency(total)}</div>
        </div>
      )}
      {!!link && status === "COMPLETED" && (
        <div className="stat">
          <div className="stat-title">Xero</div>
          <div className="stat-value font-bold text-lg">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-xs btn-primary btn-outline"
            >
              View Invoice ↗
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
